import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import scrollTo from "gatsby-plugin-smoothscroll"

import links from "../constants/links"

import variables from "../constants/variables"

const getdata = graphql`
  query {
    heftig: file(relativePath: { eq: "sal-logo.webp" }) {
      childImageSharp {
        fixed(width: 100, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Footer = ({ path }) => {
  const data = useStaticQuery(getdata)
  return (
    <StyledFooter className="py-lg-4">
      <div className="flex-container d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center mx-5">
        <div className="py-3">
          <a
            href="https://www.heftigewebseiten.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            © Heftige Webseiten {new Date().getFullYear()}
          </a>
        </div>
        <div className="mb-4 my-sm-auto">
          {path !== "/" && (
            <AniLink cover to="/" bg={variables.COLORS.primaryColor}>
              {" "}
              Home{" "}
            </AniLink>
          )}
          {path !== "/impressum/" && (
            <AniLink cover to="/impressum/" bg={variables.COLORS.primaryColor}>
              {" "}
              Impressum{" "}
            </AniLink>
          )}
          {path !== "/privacy/" && (
            <AniLink cover to="/privacy/" bg={variables.COLORS.primaryColor}>
              {" "}
              Datenschutz{" "}
            </AniLink>
          )}
        </div>
        <div>
          <AniLink
            cover
            to="/#"
            onClick={() => scrollTo(links[0].scrollTo)}
            bg={variables.COLORS.primaryColor}
          >
            <Img fixed={data.heftig.childImageSharp.fixed} alt="sal logo" />
          </AniLink>
        </div>
      </div>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  color: white;
  background: ${variables.COLORS.primaryColor};
  padding-bottom: 100px;
  .footer-container {
    max-width: 1140px;

    .flex-container {
      min-height: 78px;
    }
  }
  a {
    color: ${variables.COLORS.mainWhite};
    text-decoration: none;
  }
`

export default Footer
