const links = [
  {
    path: "/#",
    text: "Home",
    hash: "/#top",
    scrollTo: "#home",
  },
  {
    path: "/#performances",
    text: "Leistungen",
    hash: "/#performances",
    scrollTo: "#performances",
  },
  {
    path: "/#aboutus",
    text: "Über uns",
    hash: "/#aboutus",
    scrollTo: "#aboutus",
  },
  {
    path: "/#projects",
    text: "Projekte",
    hash: "/#projects",
    scrollTo: "#projects",
  },
  {
    path: "/#contact",
    text: "Kontakt",
    hash: "/#contact",
    scrollTo: "#contact",
  },
]

export default links
