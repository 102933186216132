import React from "react"
import PropTypes from "prop-types"
import "bootstrap/dist/css/bootstrap.min.css"
import { createGlobalStyle } from "styled-components"

import variables from "../constants/variables"

import Navigation from "./Navigation"
import Footer from "../components/Footer"

const Layout = ({ children, path }) => {
  return (
    <>
      <GlobalStyle />
      <Navigation />
      <div>
        <main>{children}</main>
      </div>
      <Footer path={path} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
  }

  body {
    background-color: white;
		color: black;
  }

  html,
  body {
    margin: 0;
    padding: 0;
	}

	hr {
		background: ${variables.COLORS.primaryColor};
		height: 3px !important;
		margin-bottom: 3rem !important;
	}

	ul {
		list-style-type: circle;
    padding-left: 20px;
	}
`

export default Layout
