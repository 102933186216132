const variables = {
  COLORS: {
    primaryColor: "#1a6eb8",
    mainBlue: "#0c3859",
    buttonBlue: "#144d65",
    mainYellow: "#dddc21",
    lightBlue: "#018ee9",
    mainWhite: "#fff",
    offWhite: "#f7f7f7",
    mainBlack: "#222",
    mainGrey: "#e9e9e9",
    darkGrey: "#afafaf",
  },
  TRANSITIONS: {
    mainTransition: "all 0.3s linear",
  },
  SPACING: {
    mainSpacing: "4px",
  },
  SHADOWS: {
    lightShadow: "2px 5px 3px 0px rgba(0, 0, 0, 0.5)",
    darkShadow: "4px 10px 5px 0px rgba(0, 0, 0, 0.5)",
  },
}

export default variables
