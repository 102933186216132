import React, { useEffect } from "react"
import styled from "styled-components"
import { Nav, Navbar, Container } from "react-bootstrap"
import {
  faTools,
  faHome,
  faUserCircle,
  faProjectDiagram,
  faPhone,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import scrollTo from "gatsby-plugin-smoothscroll"

import links from "../constants/links"
import variables from "../constants/variables"

const getData = graphql`
  query {
    intro: file(relativePath: { eq: "sal-logo.webp" }) {
      childImageSharp {
        fixed(quality: 90, width: 125) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(relativePath: { eq: "cover.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const tabs = [
  {
    route: links[0].path,
    icon: faHome,
    label: links[0].text,
    scrollTo: links[0].scrollTo,
  },
  {
    route: links[1].path,
    icon: faTools,
    label: links[1].text,
    scrollTo: links[1].scrollTo,
  },
  {
    route: links[2].path,
    icon: faUserCircle,
    label: links[2].text,
    scrollTo: links[2].scrollTo,
  },
  {
    route: links[3].path,
    icon: faProjectDiagram,
    label: links[3].text,
    scrollTo: links[3].scrollTo,
  },
  {
    route: links[4].path,
    icon: faPhone,
    label: links[4].text,
    scrollTo: links[4].scrollTo,
  },
]

const Navigation = ({ bg }) => {
  const data = useStaticQuery(getData)
  useEffect(() => {
    let scrollpos = window.scrollY
    const header = document.querySelector("nav")

    const header_height = header.offsetHeight

    const add_class_on_scroll = () => header.classList.add("fade-in")
    const remove_class_on_scroll = () => header.classList.remove("fade-in")
    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY
      if (scrollpos >= header_height) {
        add_class_on_scroll()
      } else {
        remove_class_on_scroll()
      }
    })
  }, [])
  return (
    <>
      {/* Top Bar*/}
      <StyledNavbar
        className="fixed-top navigation d-none d-lg-block"
        expand="lg"
      >
        <Container fluid>
          <AniLink
            cover
            to={links[0].path}
            bg={variables.COLORS.primaryColor}
            onClick={() => scrollTo(links[0].scrollTo)}
          >
            <Img
              fixed={data.intro.childImageSharp.fixed}
              alt="Twinmotion logo"
            />
          </AniLink>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {links.map((link) => {
                return (
                  <AniLink
                    key={link.text}
                    cover
                    bg={variables.COLORS.primaryColor}
                    to={link.path}
                    onClick={() => scrollTo(link.scrollTo)}
                  >
                    {link.text}
                  </AniLink>
                )
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </StyledNavbar>

      {/* Bottom Tab Navigator */}
      <StyledNav
        className="navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav"
        role="navigation"
      >
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <Nav.Item key={`tab-${index}`}>
                <AniLink
                  cover
                  bg={variables.COLORS.primaryColor}
                  to={tab.route}
                  onClick={() => scrollTo(tab.scrollTo)}
                  className="nav-link bottom-nav-link"
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon size="lg" icon={tab.icon} />
                    <div className="bottom-tab-label">{tab.label}</div>
                  </div>
                </AniLink>
              </Nav.Item>
            ))}
          </div>
        </Nav>
      </StyledNav>
    </>
  )
}
const StyledNavbar = styled(Navbar)`
  &.fade-in {
    background-color: rgba(26, 86, 208, 0.5);
    color: black;
  }

  a {
    text-decoration: none;
    color: white !important;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-right: 20px;
  }
`

const StyledNav = styled.nav`
  .navbar {
    border-bottom: solid 1px #ebebeb;
  }
  .bottom-tab-nav {
    border-top: solid 1px #ebebeb;
  }
  .nav-link,
  .bottom-nav-link {
    color: white;
  }
  /* .bottom-nav-link.active {
    color: lightgray;
  } */
  .bottom-tab-label {
    font-size: 12px;
  }
  background-color: ${variables.COLORS.primaryColor};
  box-shadow: 0px 10px 5px 10px rgba(0, 0, 0, 0.75);
`

export default Navigation
